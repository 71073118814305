.skill-comp{
    padding-top: 60px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 30px;
}

.skill-comp .skill-comp-left p{
    text-align: justify;
    font-size: 1rem;
    margin-bottom: 20px;
}

.skill-comp .skill-comp-left p span{
    color: var(--magenda);
    font-weight: 600;
}

.skill-comp .skill-comp-left h5{
    color: var(--indigo);
    font-size: 1rem;
}

.skill-comp .skill-comp-left h5 a{
    color: var(--magenda);
}

.skill-comp-right img{
    width: 100%;
    border-radius: 10% 0 10% 0;
}