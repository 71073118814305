@media (max-width: 1300px) {

}
@media (max-width: 1200px) {
  
.about_right .group_pic::before{
  width: 150px;
  height: 150px;
}
.about_right .group_pic::after{
  width: 150px;
  height: 150px;
}
}
@media (max-width: 1100px) {
  .about_right .group_pic{
    width: 100%;
  }
  .career .container .row{
    grid-template-columns: 1fr 1fr;
  }
  .faq h4{
    font-size: 0.75rem;
}
}

@media (max-width: 992px){
  .head .container{
    flex-direction: column;
    gap: 10px;
}

.sampurna .container{
  gap: 20px;
  padding: 2rem 1.75rem;
}

.sam_left h1{
  font-size: 2rem;
}
.sam_right p{
  font-size: 1rem;
}
.services .container{
  grid-template-columns: 1.5fr 1fr;
}
.about_left{
  width: 50%;
}
.about_right{
  width: 45%;
}
.about_left h1{
  font-size: 2.25rem;
  margin-bottom: 2rem;
}

.about_left h1::before{
  font-size: 0.90rem;
}

.about_left p{
  font-size: 0.85rem;
}
.about_right .group_pic::before{
  width: 120px;
  height: 120px;
}
.about_right .group_pic::after{
  width: 120px;
  height: 120px;
}
.vision .container{
  padding: 40px 0 20px;
}
.vision_left h1{
  font-size: 1.5rem;
}
.first_one h4, .sec_one h4{
  font-size: 1rem;
}
.first_one p{
  margin-bottom: 20px;
}
.first_one p,.sec_one p{
  font-size: 0.75rem;
}

.testimonial .container .testi_heading h1{
  font-size: 2.25rem;
}
.testimonial .swiper-slide{
  grid-template-columns: 1fr;
  height: auto;
}

.test_left{
  height: 300px;
}
 .test_left .test_img{
  position: relative;
  bottom: 0;
  right: 0;
  width: 200px;
  height: 200px;
}
.test_left .test_img::before{
  width: 150px;
  height: 150px;
  top: -50px;
  left: -50px;
}
.test_right{
  width: 100%;
}
.test_right p{
  width: 100%;
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 20px;
}
.test_right h2{
  text-align: center;
  font-size: 1.25rem;
  width: 100%;
}
.test_right h4{
  text-align: center;
  font-size: 1rem;
  width: 100%;
}
footer .container{
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 50px;
}
.newspaper_item{
  width: 300px;
}
.col-1 p{
  font-size: 0.90rem;
}
.mf .container .mfright {
  width: 95%;
}
.mf .container .mfleft h1{
  font-size: 2rem;
}
.mf .container .mfleft p{
  font-size: 0.9rem;
}
.socials .container .eclinic .eleft{
  width: 60%;
}
.socials .container .eclinic .eright{
  width: 40%;
}
.contact .container{
  grid-template-columns: 1fr 1fr;
}
.contact .right-container{
  width: 90%;
}

.hero_box_contain h2, #hero_box_contain_skill h2{
  font-size: 1.75rem;
}
.hero_box_contain h2 span, #hero_box_contain_skill h2 span{
  font-size: 2rem;
}

@keyframes slideAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 50%;
  }
}
}

@media (min-width: 768px) and (max-width: 878px) {
  nav .container{
    width: 95%;
  }
  .menu ul li{
    padding: 10px 5px;
  }
  .career .container .row {
    grid-template-columns: 1fr 1fr;
  }
  
.skill-comp{
  grid-template-columns: 1fr;
}
}

@media (max-width: 768px){
  .menu{
    display: none;
  }
  .menu-btn{
      display: block;
  }
  .swiper-slide{
    height: 50vh;
  }
  .sampurna .container{
    flex-direction: column;
  }
  
  .sam_left{
    border-right: 0;
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 2px solid var(--indigo);
  }
  .sam_right p{
    text-align: center;
  }
  .services .container{
    grid-template-columns: 1fr;
  }
  .about_left{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 60px;
}

.about_left h1{
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.about_left h1::before{
    font-size: 1rem;
}

.about_left p{
    font-size: 1rem;
    text-align: justify;
    color: var(--indigo);
}

.about_right{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}
.about_right .group_pic::before{
    width: 150px;
    height: 150px;
}
.about_right .group_pic::after{
    width: 150px;
    height: 150px;
}
.vision .container{
  grid-template-columns: 1fr;
}

.vision .container > div:nth-child(1){
  order: 2;
}

.vision_right .vision_pic{
  height: 300px;
  width: 100%;
}
.vision_pic img{
  object-fit: cover;
  border-radius: 0;
}

.vision_left h1{
  font-size: 2.25rem;
}

.first_one h4, .sec_one h4{
  font-size: 1.5rem;
}

.first_one p,.sec_one p{
  font-size: 1rem;
}
.testimonial .container{
  width: 90%;
}
.career .container .row {
  grid-template-columns: 1fr;
}
.col-1{
  order: 2;
  margin-top: 50px;
}
.col-1 h1{
  font-size: 2.5rem;
}
.col-1 p{
  font-size: 1.25rem;
}

.col-1 h4{
  color: var(--indigo);
  font-size: 1.25rem;
}
.col-1 > div ul li{
  font-size: 1rem;
}
.faqs_container{
  grid-template-columns: 1fr;
}
.socials .container .eclinic {
  flex-wrap: wrap;
}

.socials .container .eclinic .eleft{
  width: 100%;
}
.socials .container .eclinic .eright{
  width: 100%;
}
.skill-comp{
  grid-template-columns: 1fr;
}
.board {
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.boardright {
  order: 1;
}
.boardleft {
  order: 2;
}
.boardleft h1, .boardleft h2{
  text-align: center;
}
.boardright .img {
  width: 180px;
  height: 180px;
}
.gallery .box{
  flex-direction: column;
}
.gallery .box > div{
  width: 100%;
}
.gallery .box > div .gallery-img:last-child{
  height: auto;
}
.gallery .box div:last-child .gallery-img:nth-child(9){
  height: auto;
}
.gallery .box div:first-child .gallery-img:nth-child(10) img{
  transform: scale(1);
}
.gallery .box div:first-child .gallery-img:nth-child(10) img:hover{
  transform: scale(1.1);
}
.gallery .box div:last-child .gallery-img:nth-child(10) img{
  transform: scale(1);
}

.gallery .box div:last-child .gallery-img:nth-child(10) img:hover{
  transform: scale(1.1);
}
.contact .container{
  grid-template-columns: 1fr;
}
.contact-right{
  justify-content: left;
}
.name{
  margin-right: 10px;
}
.right-container{
  width: 90%;
}
/* .map iframe{
  height: 350px;
} */
.network-item{
  grid-template-columns: 3fr 1fr;
}
.network-left h1{
  font-size: 1.5rem;
}
.hero_box_contain h2, #hero_box_contain_skill h2{
  padding-left: 40px;
  font-size: 1.5rem;
}
.hero_box_contain h2 span, #hero_box_contain_skill h2 span{
  font-size: 1.70rem;
}
}

@media (max-width: 576px) {
    .head{
        display: none;
    }

    #back_img1{
      background-position: 90% center;
  }
  #back_img2{
      background-position: 30% center;
  }
  #back_img3{
      background-position: 50% center;
  }
  .about_right .group_pic::before{
    width: 120px;
    height: 120px;
    left: -20px;
  top: -20px;
}
.about_right .group_pic::after{
    width: 120px;
    height: 120px;
    right: -20px;
  bottom: -20px;
}
.vision_left h1{
  font-size: 1.75rem;
}

.first_one h4, .sec_one h4{
  font-size: 1.25rem;
}

.first_one p,.sec_one p{
  font-size: 1rem;
}
.partner .container h1{
  font-size: 1.5rem;
}
footer .container{
  grid-template-columns: 1fr;
}
.hero .container{
  flex-direction: column;
  gap: 20px;
}
.container .popup-img img{
  height: auto;
  width: 90%;
}
.col-1 h1{
  font-size: 2.25rem;
}
.col-1 p{
  font-size: 1rem;
}
.geo h1{
  font-size: 2rem;
}
.geo p{
  font-size: 0.9rem;
}
.map ol li{
  font-weight: 500;
  font-size: 1rem;
}
.geographical_presence .container .map img{
  width: 200px;
}
.popup-main .popup-content{
  height: auto;
  width: 90%;
}
.popup-main .popup-content img{
  height: auto;
  width: 100%;
}
.network-item{
  grid-template-columns:  1fr;
}
.hero_box_contain h2, #hero_box_contain_skill h2{
  padding-left: 10px;
  font-size: 1.25rem;
}
.hero_box_contain h2 span, #hero_box_contain_skill h2 span{
  line-height: 1.2;
  font-size: 1.75rem;
}
#hero_box_contain_skill h2{
  padding: 0;
}

@keyframes slideAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 55%;
  }
}

}

@media (max-width: 350px) {
  .sam_right p{
    font-size: 0.85rem;
  }
  .service_content p{
    font-size: 0.70rem;
  }

  .service_content span{
    color: var(--white);
    font-size: 13px;
    padding: 5px 15px;
    border: 1px solid var(--white);
    border-radius: 25px;
    font-weight: 500;
    transition: all 0.5s ease;
}
.about_left{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 60px;
}

.about_left h1{
  font-size: 2rem;
  margin-bottom: 1rem;
}

.about_left h1::before{
  font-size: 0.75rem;
}

.about_left p{
  font-size: 0.75rem;
}

.about_right{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.about_right .group_pic::before{
  width: 100px;
  height: 100px;
  left: -20px;
  top: -20px;
}
.about_right .group_pic::after{
  width: 100px;
  height: 100px;
  right: -20px;
  bottom: -20px;
}
.vision_left h1{
  font-size: 1.5rem;
}

.first_one h4, .sec_one h4{
  font-size: 1rem;
}

.first_one p,.sec_one p{
  font-size: 0.75rem;
}
.test_left .test_img{
  position: relative;
  bottom: 0;
  right: 0;
  width: 150px;
  height: 150px;
}
.test_left .test_img::before{
  width: 100px;
  height: 100px;
  top: -25px;
  left: -25px;
}

}


