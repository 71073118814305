footer {
  background-color: rgb(241, 241, 241);
  padding: 50px 0px;
  margin-top: 80px;
  border-bottom: 1px solid var(--black);
}

footer .container {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 1.5fr;
  gap: 50px;
}

.company_info h1 {
  font-size: 1.25rem;
  color: var(--magenda);
}
.company_info > a {
  font-size: 0.9rem;
  color: var(--indigo);
  font-weight: 500;
  margin: 20px 0 10px;
}

.company_info .quicklink {
  font-size: 0.75rem;
}

.quicklink p {
  text-align: justify;
  color: var(--black);
}

.address h1 {
  margin-bottom: 5px;
}

.address ul li {
  display: flex;
  align-items: center;
  margin: 0 0 4px 0;
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--indigo);

}

.address ul li i {
  color: var(--magenda);
  font-size: 1.2rem;
  margin-right: 8px;
  font-weight: 900;
  transition: all 0.5s ease;
  align-self: flex-start;
}

.address ul li p {
  margin: 5px;
  margin-left: 15px;
  color: var(--indigo);
}
.address ul li a i:hover {
  opacity: 2;
  color: var(--indigo);
}

.address .social-icons{
  width: 100%;
  display: flex;
}

.social-icons > a{
  margin-right: 20px;
}

.social-icons > a .fa-facebook-f{
  color: #1877F2;
}
.social-icons > a .fa-youtube{
  color: #9c0000;
}

.grievance {
  align-items: start;
  align-self: flex-start;
}

.copyright_policy .container {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyright p {
  text-align: center;
  color: var(--magenda);
  font-weight: 500;
}

.copyright p a {
  color: var(--indigo);
  font-weight: 600;
}

.policy a {
  margin: 0 10px;
  font-size: 0.8rem;
}

.grievance {
  margin-top: 1rem;
  font-size: 1rem;
  color: var(--magenda);
  font-weight: 600;
}

.whatsapp {
  font-size: 1rem;
  color: var(--magenda);
  font-weight: 600;
}
@media (max-width: 768px) {
  .copyright_policy .container {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  .copyright_policy .container .policy {
    order: 1;
    margin: 0 5px;
  }
  .copyright_policy .container .policy a {
    font-size: 0.75rem;
  }
  .copyright_policy .container .copyright {
    order: 2;
  }
}

@media (max-width: 576px) {
  footer .container {
    grid-template-columns: 1fr;
  }
}
