.head {
  background-color: var(--magenda);
}

.head .container {
  padding: 15px 0;
}

.grievance-number{
  display: flex;
  align-items: center;
  row-gap: 2rem;
  margin: 0px 10px;
}

.nav-items{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}


.head_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red;    */
}

.head_left > a,
.head_right > a {
  margin-right: 10px;
  font-size: 15px;
  color: var(--white);
  text-decoration: none;
}

.head_left > a {
  margin-right: 15px;
}

.head_right > a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.head_right i {
  font-size: 20px;
  margin-right: 10px;
  color: var(--white);
}

.head_right span {
  color: var(--white);
}

.nav {
  position: absolute;
  z-index: 10;
  width: 100%;
  transition: all 0.5s ease;
  background-color: #ffffff69;
}

.fixed {
  position: fixed;
  background: var(--white);
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.253);
}

nav .container {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 10px;
}

.brand img {
  width: 150px;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.menu ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu ul li {
  padding: 15px 10px;
  position: relative;
}

.menu ul li i {
  color: var(--white);
  padding-left: 10px;
}

.fixed .menu ul li i,
.fixed .menu ul li a {
  color: var(--indigo);
}

.menu ul li a {
  font-size: 15px;
  position: relative;
  transition: all 0.5s ease;
  font-weight: 500;
  color: var(--white);
}

.menu ul ul {
  position: absolute;
  top: 50px;
  display: none;
  width: 230px;
  border-radius: 5px;
  background-color: aliceblue;
}

.menu_link {
  cursor: pointer;
}

.menu ul li:hover > ul {
  display: block;
}

.menu ul ul li {
  padding: 10px 20px;
  transition: all 0.5s ease;
}
.menu ul ul li:hover {
  border-radius: 5px;
  background-color: rgb(220, 220, 220);
}

.menu ul ul li a {
  color: var(--indigo);
  font-weight: 400;
  font-size: 13px;
}

.menu-btn {
  display: none;
}
.menu_btn {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--magenda);
  font-size: 18px;
  color: var(--white);
}

.phone_menu_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.3s linear;
  position: absolute;
  z-index: 14;
  top: 100%;
  left: 0;
  min-width: 100%;
}

.phone_menu_item_active {
  max-height: 1060px;
  /* overflow: scroll; */
}

.phone_menu_item a {
  padding: 8px 0;
  font-weight: 300;
  border: 1px solid #b10242;
  width: 100%;
  text-align: center;
  background-color: var(--magenda);
  color: var(--white);
  font-size: 0.75rem;
}

.phone_menu_item a i {
  margin-left: 10px;
  color: var(--white);
}

.phone_menu_item a strong {
  color: var(--white);
  font-weight: 500;
}

.scroll_top {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 50px;
  right: 30px;
  z-index: 10;
  padding: 10px 20px;
  background: var(--magenda);
  border-radius: 5px;
  color: var(--white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.42);
}

.scroll_top i {
  color: var(--white);
}

@media (max-width:1280px){
  .nav-items{
    flex-direction: column;
  }
}

@media (max-width:970px){
  .head{
    display: none;
  }
}
