.vision_mission{
    padding: 3rem 0 2rem;
}

.vision_mission .vision_content{
    padding: 0 0 40px 0;
}

.vision_mission > div h1{
    padding: 0 0 10px 0;
    font-size: 2.25rem;
    color: var(--magenda);
}