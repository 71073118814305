.network{
    padding-top: 20px;
}
.network-item{
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 20px;
    border-bottom: 1px solid #00000059;
    padding: 30px 0;
}

.network-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.network-left h1{
    color: var(--magenda);
    font-size: 2rem;
    margin-bottom: 10px;
}
.network-left p{
    text-align: justify;
}

.network-right{
    display: flex;
    justify-content: center;
    align-items: center;
}

.network-right img{
    width: 80%;
}