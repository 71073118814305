section.content{
    margin-top: 50px;
}

.content h1{
    font-size: 2rem;
    color: var(--magenda);
}

.paragraph{
    text-align: justify;
    margin: 30px 0;
}

.content-head{
    font-size: 1.2rem;
    color: var(--indigo);
}

p{
    margin: 10px 0;
}