.testimonial{
    margin-bottom: 60px;
}

.testimonial .container{
    width: 70%;
    overflow: hidden;
}

.testimonial .container .testi_heading{
    /* border: 1px solid black; */
    margin: 2rem 0 1.5rem 0;
}

.testimonial .container .testi_heading h1{
    text-align: center;
    font-size: 2rem;
    text-transform: uppercase;
    color: var(--magenda);
    position: relative;
}

.testi_heading h1::before{
    position: absolute;
    content: "what people said";
    text-transform: uppercase;
    font-size: 0.75rem;
    top: -15px;
    left: 50%;
    transform:translateX(-50%);
    color: var(--indigo);
}

.testimonial .swiper-slide {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
    height: 300px;
}
.swiper-slide .test_left .test_img{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.test_left, .test_right{
    /* border: 1px solid #000; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.test_left{
    overflow: hidden;
}

.test_right{
    flex-direction: column;
    align-items: flex-start;
}

.test_left .test_img{
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 2px solid var(--white);
    box-shadow: 0 0 0px 8px var(--magenda);
}

.test_left .test_img::before{
    position: absolute;
    content: "";
    width: 100px;
    height: 100px;
    background: var(--magenda);
    top: -40px;
    left: -40px;
    border-radius: 50%;
    z-index: -1;
}

.test_right{
    width: 100%;
}

.test_right p{
    width: 90%;
    font-size: 0.75rem;
    text-align: justify;
    margin-bottom: 30px;
}

.test_right h2{
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--magenda);
}

.test_right h4{
    font-size: 0.75rem;
    font-weight: 500;
    color: var(--indigo);
}