.cutout{
    padding: 60px 0 0;
}

.cutout .container h1{
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    color: var(--magenda);
    margin-bottom: 30px;
}

.newspaper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
}

.newspaper_item{
    height: 250px;
    width: 350px;
    border: 3px solid var(--magenda);
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .1);
    overflow: hidden;
    cursor: pointer;
    margin: 5px;
}

.newspaper_item img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: .2s linear;
    object-position: top;
    transition: all 1s ease;
}

.newspaper_item:hover img{
    transform: scale(1.1);
}

.container .popup-img{
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    height: 100%;
    width: 100%;
    z-index: 99999999;
    display: block;
}

.container .popup-img span{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 40px;
    font-weight: bolder;
    color: var(--white);
    cursor: pointer;
    z-index: 999999999;
}

.container .popup-img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 3px solid var(--magenda);
    border-radius: 5px;
    height: 90%;
    width: auto;
}