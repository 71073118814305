.faqs {
  padding: 2rem 0;
}

.faqs_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 40px;
}

.faq {
  padding: 2rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 1.4rem;
  height: fit-content;
  background: var(--magenda);
  cursor: pointer;
}

.faq h4 {
  font-size: 1rem;
  line-height: 2.2;
  color: #fff;
  font-weight: 600;
}

.faq_icon {
  align-self: flex-start;
  font-size: 1.2rem;
}

.faq_icon i{
    color: var(--white);
}

.answer {
  margin-top: 0.8rem;
  display: none;
}

.faq.open .answer {
  font-size: 0.75rem;
  color: var(--white);
  display: block;
}
