.services{
    margin: 30px 0 60px;
}

.services .container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.service_box{
    background: url("../../resource/Service/microfinance.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
    transition: all 0.5s ease;
    min-height: 300px;
}

.service_box:nth-child(2){
    background: url("../../resource/Service/skill-development.jpg");
    background-size: cover;
}

.service_box:nth-child(3){
    background: url("../../resource/Service/social-work.jpg");
    background-size: cover;
}

.service_content{
    background: #ed1967bb;
    height: 70%;
    position: absolute;
    width: 100%;
    bottom: -150px;
    padding: 20px 20px;
    transition: all 0.5s ease;
}

.service_box:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.598);
}

.service_box:hover .service_content{
    bottom: 0;
}

.service_content h5{
    color: var(--white);
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 10px;
}
.service_content p{
    color: var(--white);
    font-size: 0.75rem;
    margin-bottom: 15px;
}
.service_content span{
    color: var(--white);
    font-size: 15px;
    padding: 5px 15px;
    border: 1px solid var(--white);
    border-radius: 25px;
    font-weight: 500;
    transition: all 0.5s ease;
}

.service_content span:hover{
    color: var(--indigo);
    background: #fff;
    box-shadow: 0 0 10px #091359;
}