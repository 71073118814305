.career{
    padding-top: 50px;
}

.row{
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    padding: 30px 0;
    border-bottom: 1px solid var(--indigo);
}
.row:last-child{
    border-bottom: 0px solid var(--indigo);
}

.col-1 h1{
    font-size: 2.25rem;
    color: var(--magenda);
    margin-bottom: 30px;
}
.col-1 p{
    font-size: 1rem;
    color: var(--black);
    width: 95%;
    text-align: justify;
}

.col-1 h4{
    color: var(--indigo);
    font-size: 1rem;
}

.col-1 > div{
margin: 20px 0;
}

ol{
    padding: 15px;
}

.col-1 > div ol li{
    font-size: 0.85rem;
    padding: 5px;
    width: 95%;
    text-align: justify;
    list-style-type: upper-roman;
}

.col-2{
    display: flex;
    justify-content: center;
    align-items: center;
}

.col-2 img{
    width: 100%;
}

/* .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999999;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: transparent;
    font-size: 20px;
    cursor: pointer;
  }
  
  .modal form {
    margin-top: 20px;
  }
  
  .modal form input,
  .modal form button {
    display: block;
    margin-bottom: 10px;
  }
  
  .modal form button[type="submit"] {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
  }
   */