.mf {
    padding: 60px 0 0;
}

.mf .container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    text-align: justify;
}
.mf .container .mfleft h1{
    font-size: 2.25rem;
    color: var(--magenda);
    font-weight: bold;
}
.mf .container .mfright img{
    width: 100%;
}


@media (max-width: 750px){
    .mf .container{
    flex-wrap: wrap;
}

.mf .container .mfleft{
    margin-top: 50px;
}

.mf .container .mfright{
    width: 100%;
justify-content: center;}}
