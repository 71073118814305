.socials{
    padding-top: 60px;
}

.socials .container{
    text-align: justify;
}

.socials .container .con {
    margin-bottom: 50px;
}
.socials .container .con h1{
    font-size: 1.75rem;
    color: var(--magenda);
}

.socials .container .con h2{
    font-size: 1rem;
    font-weight: 400;
    color: var(--indigo);
}

.socials .container .con h3{
    font-size: 1.25rem;
    color: var(--indigo);
}

.socials .container .con strong{
    font-size: 1rem;
    font-weight: 700;
    color: var(--indigo);
}


.socials .container .eclinic {
    display: flex;
    place-items: center;
    gap: 30px;
    /* height: 300px; */
}

.socials .container .eclinic .eleft{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: justify;
    width: 70%;
}

.socials .container .eclinic .eleft h1{
    font-size: 1.75rem;
    color: var(--magenda);
}

.socials .container .eclinic .eright{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.socials .container .eclinic .eright img{
    width: 100%;
}