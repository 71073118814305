@import url(https://fonts.googleapis.com/css2?family=Cantora+One&family=Kaushan+Script&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Tangerine:wght@700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
:root{
  --magenda: #ed1b69;
  --indigo: #243f86;
  --white: #fff;
  --black: #000;
}

::-webkit-scrollbar{
  width: 0px;
  margin: 0;
}

html{
  scroll-behavior: smooth;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #000;
  color: var(--black);
  text-decoration: none;
  list-style-type: none;
  color: #000;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

h1{
  font-family: 'Ubuntu', sans-serif;
}

.container{
  width: 90%;
  margin: auto auto;
}





.head {
  background-color: var(--magenda);
}

.head .container {
  padding: 15px 0;
}

.grievance-number{
  display: flex;
  align-items: center;
  grid-row-gap: 2rem;
  row-gap: 2rem;
  margin: 0px 10px;
}

.nav-items{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}


.head_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red;    */
}

.head_left > a,
.head_right > a {
  margin-right: 10px;
  font-size: 15px;
  color: var(--white);
  text-decoration: none;
}

.head_left > a {
  margin-right: 15px;
}

.head_right > a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.head_right i {
  font-size: 20px;
  margin-right: 10px;
  color: var(--white);
}

.head_right span {
  color: var(--white);
}

.nav {
  position: absolute;
  z-index: 10;
  width: 100%;
  transition: all 0.5s ease;
  background-color: #ffffff69;
}

.fixed {
  position: fixed;
  background: var(--white);
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.253);
}

nav .container {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 10px;
}

.brand img {
  width: 150px;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.menu ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu ul li {
  padding: 15px 10px;
  position: relative;
}

.menu ul li i {
  color: var(--white);
  padding-left: 10px;
}

.fixed .menu ul li i,
.fixed .menu ul li a {
  color: var(--indigo);
}

.menu ul li a {
  font-size: 15px;
  position: relative;
  transition: all 0.5s ease;
  font-weight: 500;
  color: var(--white);
}

.menu ul ul {
  position: absolute;
  top: 50px;
  display: none;
  width: 230px;
  border-radius: 5px;
  background-color: aliceblue;
}

.menu_link {
  cursor: pointer;
}

.menu ul li:hover > ul {
  display: block;
}

.menu ul ul li {
  padding: 10px 20px;
  transition: all 0.5s ease;
}
.menu ul ul li:hover {
  border-radius: 5px;
  background-color: rgb(220, 220, 220);
}

.menu ul ul li a {
  color: var(--indigo);
  font-weight: 400;
  font-size: 13px;
}

.menu-btn {
  display: none;
}
.menu_btn {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--magenda);
  font-size: 18px;
  color: var(--white);
}

.phone_menu_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.3s linear;
  position: absolute;
  z-index: 14;
  top: 100%;
  left: 0;
  min-width: 100%;
}

.phone_menu_item_active {
  max-height: 1060px;
  /* overflow: scroll; */
}

.phone_menu_item a {
  padding: 8px 0;
  font-weight: 300;
  border: 1px solid #b10242;
  width: 100%;
  text-align: center;
  background-color: var(--magenda);
  color: var(--white);
  font-size: 0.75rem;
}

.phone_menu_item a i {
  margin-left: 10px;
  color: var(--white);
}

.phone_menu_item a strong {
  color: var(--white);
  font-weight: 500;
}

.scroll_top {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 50px;
  right: 30px;
  z-index: 10;
  padding: 10px 20px;
  background: var(--magenda);
  border-radius: 5px;
  color: var(--white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.42);
}

.scroll_top i {
  color: var(--white);
}

@media (max-width:1280px){
  .nav-items{
    flex-direction: column;
  }
}

@media (max-width:970px){
  .head{
    display: none;
  }
}

footer {
  background-color: rgb(241, 241, 241);
  padding: 50px 0px;
  margin-top: 80px;
  border-bottom: 1px solid var(--black);
}

footer .container {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 1.5fr;
  grid-gap: 50px;
  gap: 50px;
}

.company_info h1 {
  font-size: 1.25rem;
  color: var(--magenda);
}
.company_info > a {
  font-size: 0.9rem;
  color: var(--indigo);
  font-weight: 500;
  margin: 20px 0 10px;
}

.company_info .quicklink {
  font-size: 0.75rem;
}

.quicklink p {
  text-align: justify;
  color: var(--black);
}

.address h1 {
  margin-bottom: 5px;
}

.address ul li {
  display: flex;
  align-items: center;
  margin: 0 0 4px 0;
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--indigo);

}

.address ul li i {
  color: var(--magenda);
  font-size: 1.2rem;
  margin-right: 8px;
  font-weight: 900;
  transition: all 0.5s ease;
  align-self: flex-start;
}

.address ul li p {
  margin: 5px;
  margin-left: 15px;
  color: var(--indigo);
}
.address ul li a i:hover {
  opacity: 2;
  color: var(--indigo);
}

.address .social-icons{
  width: 100%;
  display: flex;
}

.social-icons > a{
  margin-right: 20px;
}

.social-icons > a .fa-facebook-f{
  color: #1877F2;
}
.social-icons > a .fa-youtube{
  color: #9c0000;
}

.grievance {
  align-items: start;
  align-self: flex-start;
}

.copyright_policy .container {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyright p {
  text-align: center;
  color: var(--magenda);
  font-weight: 500;
}

.copyright p a {
  color: var(--indigo);
  font-weight: 600;
}

.policy a {
  margin: 0 10px;
  font-size: 0.8rem;
}

.grievance {
  margin-top: 1rem;
  font-size: 1rem;
  color: var(--magenda);
  font-weight: 600;
}

.whatsapp {
  font-size: 1rem;
  color: var(--magenda);
  font-weight: 600;
}
@media (max-width: 768px) {
  .copyright_policy .container {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  .copyright_policy .container .policy {
    order: 1;
    margin: 0 5px;
  }
  .copyright_policy .container .policy a {
    font-size: 0.75rem;
  }
  .copyright_policy .container .copyright {
    order: 2;
  }
}

@media (max-width: 576px) {
  footer .container {
    grid-template-columns: 1fr;
  }
}

.swiper-slide{
    width: 100%;
    height: 95vh;
}

.back_img{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

/* .back_img h1{
    font-size: 3rem;
    width: 90%;
    margin: auto;
    color: #fff;
} */

#back_img1{
    background: url(/static/media/Home_BG_1.b3a45033.jpg);
    /* box-shadow: inset 0 0 0 2000px rgba(0, 0,0, 0.3); */
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
#back_img2{
    background: url(/static/media/Home_BG_2.ba3f0455.jpeg);
    /* box-shadow: inset 0 0 0 2000px rgba(0, 0,0, 0.3); */
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
#back_img3{
    background: url(/static/media/Home_BG_3.5ec19dfb.jpg);
    background-size: cover;
    /* box-shadow: inset 0 0 0 2000px rgba(0, 0,0, 0.3); */
    background-position: center center;
    background-repeat: no-repeat;
}

.sampurna{
    width: 100%;
    position: relative;
    top: -50px;
    z-index: 2;
}

.sampurna .container{
    padding: 2.5rem 2rem;
    background-color: #fff;
    display: flex;
    grid-gap: 50px;
    gap: 50px;
    box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.422);
}

.sam_left{
    width: 60%;
    border-right: 2px solid var(--indigo);
    display: flex;
    align-items: center;
    justify-content: center;
}

.sam_left h1{
    font-size: 2.5rem;
    color: var(--magenda);
    letter-spacing: 2px;
}

.sam_right{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sam_right p{
    font-size: 1.2rem;
    color: var(--indigo);
    text-align: justify;
}

@-webkit-keyframes slideAnimation {
    0% {
      width: 0;
    }
    100% {
      width: 45%;
    }
  }

@keyframes slideAnimation {
    0% {
      width: 0;
    }
    100% {
      width: 45%;
    }
  }
  
  .hero_box_contain {
    margin-top: 150px;
    width: 0;
    height: 120px;
    position: relative;
    background: var(--indigo);
    -webkit-animation: slideAnimation 0.5s ease-in-out forwards;
            animation: slideAnimation 0.5s ease-in-out forwards;
    display: flex;
    align-items: center;
}

#hero_box_contain_skill{
    margin-left: auto;
}

#hero_box_contain_skill::before{
    position: absolute;
    content: "";
    width: 86px;
    height: 86px;
    /* border: 1px solid red; */
    top: 17px;
    left: -42px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    background: var(--indigo);
    /* opacity: 0.8; */
}

.hero_box_contain:after{
    position: absolute;
    content: "";
    width: 86px;
    height: 86px;
    /* border: 1px solid red; */
    top: 17px;
    right: -42px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    background: var(--indigo);
    /* opacity: 0.8; */
}

#hero_box_contain_skill h2{
    color: var(--white);
    opacity: 0;
    -webkit-animation: textAnimation 0.5s ease-in-out forwards 0.5s;
            animation: textAnimation 0.5s ease-in-out forwards 0.5s;
    padding-left: 40px;
    text-transform: capitalize;
    /* font-family: 'Cantora One', sans-serif; */
    font-weight: 400;
    font-size: 2rem;
}
#hero_box_contain_skill h2 span{
    color: var(--white);
    /* font-family: 'Kaushan Script', cursive; */
    font-size: 2.5rem;
    line-height: 1;
    font-weight: 400;
}
.hero_box_contain h2 {
    color: var(--white);
    opacity: 0;
    -webkit-animation: textAnimation 0.5s ease-in-out forwards 0.5s;
            animation: textAnimation 0.5s ease-in-out forwards 0.5s;
    padding-left: 50px;
    text-transform: capitalize;
    /* font-family: 'Cantora One', sans-serif; */
    font-weight: 600;
    font-size: 2rem;
    z-index: 2;
}

.hero_box_contain h2 span{
    color: var(--white);
    /* font-family: 'Cantora One', sans-serif;
    font-family: 'Kaushan Script', cursive; */
    font-size: 2.5rem;
    line-height: 1;
    font-weight: 400;
}


@-webkit-keyframes textAnimation {
    0% {
        /* width: 60%; */
        opacity: 0;
    }
    100% {
        /* width: 100%; */
      opacity: 1;
    }
  }


@keyframes textAnimation {
    0% {
        /* width: 60%; */
        opacity: 0;
    }
    100% {
        /* width: 100%; */
      opacity: 1;
    }
  }
.services{
    margin: 30px 0 60px;
}

.services .container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    gap: 10px;
}

.service_box{
    background: url(/static/media/microfinance.5bb106db.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
    transition: all 0.5s ease;
    min-height: 300px;
}

.service_box:nth-child(2){
    background: url(/static/media/skill-development.b33a557f.jpg);
    background-size: cover;
}

.service_box:nth-child(3){
    background: url(/static/media/social-work.13d499df.jpg);
    background-size: cover;
}

.service_content{
    background: #ed1967bb;
    height: 70%;
    position: absolute;
    width: 100%;
    bottom: -150px;
    padding: 20px 20px;
    transition: all 0.5s ease;
}

.service_box:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.598);
}

.service_box:hover .service_content{
    bottom: 0;
}

.service_content h5{
    color: var(--white);
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 10px;
}
.service_content p{
    color: var(--white);
    font-size: 0.75rem;
    margin-bottom: 15px;
}
.service_content span{
    color: var(--white);
    font-size: 15px;
    padding: 5px 15px;
    border: 1px solid var(--white);
    border-radius: 25px;
    font-weight: 500;
    transition: all 0.5s ease;
}

.service_content span:hover{
    color: var(--indigo);
    background: #fff;
    box-shadow: 0 0 10px #091359;
}
.about_us{
    margin: 120px 0 80px;
}

.about_us .container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.about_left{
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about_left h1{
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--magenda);
    position: relative;
    margin-bottom: 2rem;
}

.about_left p{
    font-size: 1rem;
    text-align: justify;
    color: var(--indigo);
}

.about_right{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.about_right .group_pic{
    width: 80%;
    height: 100%;
    border: 2px solid var(--white);
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.476);
}

.about_right .group_pic img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}
.about_right .group_pic::before{
    position: absolute;
    width: 180px;
    height: 180px;
    background: var(--magenda);
    content: "";
    top: -30px;
    left: -30px;
    z-index: -1;
}
.about_right .group_pic::after{
    position: absolute;
    width: 180px;
    height: 180px;
    background: var(--magenda);
    content: "";
    bottom: -30px;
    right: -30px;
    z-index: -1;
}
.vision{
    margin-bottom: 60px;
    background-color: rgb(236, 236, 236);
}

.vision .container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    gap: 50px;
    padding: 50px 0;
}

.vision_left{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.vision_left h1{
    font-size: 2.2rem;
    color: var(--magenda);
    margin-bottom: 20px;
}

.first_one h2, .sec_one h2{
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--magenda);
    margin-bottom: 10px;
}
.first_one p,.sec_one p{
    font-size: 0.9rem;
    margin-bottom: 50px;
    color: rgb(138, 138, 138);
    text-align: justify;;
}

.sec_one p{
    margin-bottom: 20px;
}

.vision_right{
    display: flex;
    align-items: center;
    justify-content: center;
}
    
.vision_right .vision_pic{
    display: flex;
    justify-content: center;
    position: relative;
}

.vision_pic img{
   width: 100%;
   border-radius: 60% 0 0 60%;
}

.testimonial{
    margin-bottom: 60px;
}

.testimonial .container{
    width: 70%;
    overflow: hidden;
}

.testimonial .container .testi_heading{
    /* border: 1px solid black; */
    margin: 2rem 0 1.5rem 0;
}

.testimonial .container .testi_heading h1{
    text-align: center;
    font-size: 2rem;
    text-transform: uppercase;
    color: var(--magenda);
    position: relative;
}

.testi_heading h1::before{
    position: absolute;
    content: "what people said";
    text-transform: uppercase;
    font-size: 0.75rem;
    top: -15px;
    left: 50%;
    -webkit-transform:translateX(-50%);
            transform:translateX(-50%);
    color: var(--indigo);
}

.testimonial .swiper-slide {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 20px;
    gap: 20px;
    height: 300px;
}
.swiper-slide .test_left .test_img{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.test_left, .test_right{
    /* border: 1px solid #000; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.test_left{
    overflow: hidden;
}

.test_right{
    flex-direction: column;
    align-items: flex-start;
}

.test_left .test_img{
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 2px solid var(--white);
    box-shadow: 0 0 0px 8px var(--magenda);
}

.test_left .test_img::before{
    position: absolute;
    content: "";
    width: 100px;
    height: 100px;
    background: var(--magenda);
    top: -40px;
    left: -40px;
    border-radius: 50%;
    z-index: -1;
}

.test_right{
    width: 100%;
}

.test_right p{
    width: 90%;
    font-size: 0.75rem;
    text-align: justify;
    margin-bottom: 30px;
}

.test_right h2{
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--magenda);
}

.test_right h4{
    font-size: 0.75rem;
    font-weight: 500;
    color: var(--indigo);
}
.partner{
    margin-bottom: 60px;
}

.partner .container h1{
    text-align: center;
    padding: 2rem;
    font-size: 2.3rem;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--magenda);
}

.partner .container .swiper-slide{
    width: 100px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lender_item img{
    width: 100%;
    aspect-ratio: 3/2;
    object-fit: contain;
}
.hero{
    width: 100%;
    height: 50vh;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-attachment: fixed; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero .container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hero .container h1{
    color: var(--white);
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
}

.hero .container p a{
    color: var(--magenda);
    font-weight: 400;
    position: relative;
}

.hero .container p a::after{
    position: absolute;
    content: "";
    bottom: -2px;
    height: 3px;
    border-radius: 5px;
    width: 0%;
    background-color: var(--magenda);
    left: 0;
    transition: all 1s ease;
}

.hero .container p a:hover:after{
    width: 80%;
}

.hero .container p{
    color: var(--white);
}
.cutout{
    padding: 60px 0 0;
}

.cutout .container h1{
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    color: var(--magenda);
    margin-bottom: 30px;
}

.newspaper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
}

.newspaper_item{
    height: 250px;
    width: 350px;
    border: 3px solid var(--magenda);
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .1);
    overflow: hidden;
    cursor: pointer;
    margin: 5px;
}

.newspaper_item img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: .2s linear;
    object-position: top;
    transition: all 1s ease;
}

.newspaper_item:hover img{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.container .popup-img{
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    height: 100%;
    width: 100%;
    z-index: 99999999;
    display: block;
}

.container .popup-img span{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 40px;
    font-weight: bolder;
    color: var(--white);
    cursor: pointer;
    z-index: 999999999;
}

.container .popup-img img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border: 3px solid var(--magenda);
    border-radius: 5px;
    height: 90%;
    width: auto;
}
.career{
    padding-top: 50px;
}

.row{
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    padding: 30px 0;
    border-bottom: 1px solid var(--indigo);
}
.row:last-child{
    border-bottom: 0px solid var(--indigo);
}

.col-1 h1{
    font-size: 2.25rem;
    color: var(--magenda);
    margin-bottom: 30px;
}
.col-1 p{
    font-size: 1rem;
    color: var(--black);
    width: 95%;
    text-align: justify;
}

.col-1 h4{
    color: var(--indigo);
    font-size: 1rem;
}

.col-1 > div{
margin: 20px 0;
}

ol{
    padding: 15px;
}

.col-1 > div ol li{
    font-size: 0.85rem;
    padding: 5px;
    width: 95%;
    text-align: justify;
    list-style-type: upper-roman;
}

.col-2{
    display: flex;
    justify-content: center;
    align-items: center;
}

.col-2 img{
    width: 100%;
}

/* .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999999;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: transparent;
    font-size: 20px;
    cursor: pointer;
  }
  
  .modal form {
    margin-top: 20px;
  }
  
  .modal form input,
  .modal form button {
    display: block;
    margin-bottom: 10px;
  }
  
  .modal form button[type="submit"] {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
  }
   */
.geographical_presence{
    padding-top: 60px;
}

.geo h1{
    color: var(--magenda);
    font-size: 2.25rem;
    margin-bottom: 20px;
}
.geo p{
    /* border: 1px solid red; */
    margin-bottom: 3rem;
}

ol li{
    font-weight: 500;
    color: var(--magenda);
    padding: 3px 0;
    text-transform: uppercase;
}

ol li ul li{
    font-size: 0.75rem;
    padding-left: 20px;
    color: var(--indigo);
    text-transform: capitalize;
}

.geographical_presence .container .map {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 450px;
    /* border: 1px solid red; */
    margin-top: 20px;
}

.geographical_presence .container .map img{
    width: 350px;
}

.faqs {
  padding: 2rem 0;
}

.faqs_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 40px;
}

.faq {
  padding: 2rem 1.5rem;
  display: flex;
  align-items: center;
  grid-gap: 1.4rem;
  gap: 1.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: var(--magenda);
  cursor: pointer;
}

.faq h4 {
  font-size: 1rem;
  line-height: 2.2;
  color: #fff;
  font-weight: 600;
}

.faq_icon {
  align-self: flex-start;
  font-size: 1.2rem;
}

.faq_icon i{
    color: var(--white);
}

.answer {
  margin-top: 0.8rem;
  display: none;
}

.faq.open .answer {
  font-size: 0.75rem;
  color: var(--white);
  display: block;
}

section.content{
    margin-top: 50px;
}

.content h1{
    font-size: 2rem;
    color: var(--magenda);
}

.paragraph{
    text-align: justify;
    margin: 30px 0;
}

.content-head{
    font-size: 1.2rem;
    color: var(--indigo);
}

p{
    margin: 10px 0;
}
.vision_mission{
    padding: 3rem 0 2rem;
}

.vision_mission .vision_content{
    padding: 0 0 40px 0;
}

.vision_mission > div h1{
    padding: 0 0 10px 0;
    font-size: 2.25rem;
    color: var(--magenda);
}
.backgound{
    padding: 3rem 0 1rem;
}

.backgound p{
    text-align: justify;
}

.backgound p span{
    color: var(--magenda);
    font-weight: 600;
    font-size: 1.1rem;
}
.mf {
    padding: 60px 0 0;
}

.mf .container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 30px;
    gap: 30px;
    text-align: justify;
}
.mf .container .mfleft h1{
    font-size: 2.25rem;
    color: var(--magenda);
    font-weight: bold;
}
.mf .container .mfright img{
    width: 100%;
}


@media (max-width: 750px){
    .mf .container{
    flex-wrap: wrap;
}

.mf .container .mfleft{
    margin-top: 50px;
}

.mf .container .mfright{
    width: 100%;
justify-content: center;}}

.skill-comp{
    padding-top: 60px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 30px;
    gap: 30px;
}

.skill-comp .skill-comp-left p{
    text-align: justify;
    font-size: 1rem;
    margin-bottom: 20px;
}

.skill-comp .skill-comp-left p span{
    color: var(--magenda);
    font-weight: 600;
}

.skill-comp .skill-comp-left h5{
    color: var(--indigo);
    font-size: 1rem;
}

.skill-comp .skill-comp-left h5 a{
    color: var(--magenda);
}

.skill-comp-right img{
    width: 100%;
    border-radius: 10% 0 10% 0;
}
.socials{
    padding-top: 60px;
}

.socials .container{
    text-align: justify;
}

.socials .container .con {
    margin-bottom: 50px;
}
.socials .container .con h1{
    font-size: 1.75rem;
    color: var(--magenda);
}

.socials .container .con h2{
    font-size: 1rem;
    font-weight: 400;
    color: var(--indigo);
}

.socials .container .con h3{
    font-size: 1.25rem;
    color: var(--indigo);
}

.socials .container .con strong{
    font-size: 1rem;
    font-weight: 700;
    color: var(--indigo);
}


.socials .container .eclinic {
    display: flex;
    place-items: center;
    grid-gap: 30px;
    gap: 30px;
    /* height: 300px; */
}

.socials .container .eclinic .eleft{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: justify;
    width: 70%;
}

.socials .container .eclinic .eleft h1{
    font-size: 1.75rem;
    color: var(--magenda);
}

.socials .container .eclinic .eright{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.socials .container .eclinic .eright img{
    width: 100%;
}
.gallery{
    padding-top: 60px;
}

.gallery .box{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.gallery .box > div{
    display: flex;
    flex-direction: column;
    width: 32.5%;
}
.gallery .box .gallery-img{
    overflow: hidden;
    border-radius: 20px;
    margin-bottom: 10px;
}
.gallery .box .gallery-img img{
    width: 100%;
    transition: all 0.5s linear;
    object-fit: cover;
    object-position: center;
}

.gallery .box > div .gallery-img:last-child{
    height: 250px;
}
.gallery .box div:last-child .gallery-img:nth-child(9){
    height: 370px;
}
.gallery .box div:first-child .gallery-img:nth-child(10) img{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.gallery .box div:first-child .gallery-img:nth-child(10) img:hover{
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
.gallery .box div:last-child .gallery-img:nth-child(10) img{
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.gallery .box div:last-child .gallery-img:nth-child(10) img:hover{
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}

.gallery-popup-img{
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.925);
    height: 100%;
    width: 100%;
    z-index: 99999999;
    display: block;
}

.gallery-popup-img span{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 40px;
    font-weight: bolder;
    color: var(--magenda);
    cursor: pointer;
    z-index: 999999999;
}

.popup-main{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.popup-main .popup-content{
    height: 90%;
    width: auto;
    border-radius: 5px;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gallery .box .gallery-img:hover img{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.popup-main .popup-content img{
    height: 80%;
    border-radius: 5px;
}
.popup-main .popup-content p{
    font-size: 0.9rem;
    text-align: center;
    width: 90%;
    color: var(--white);
}

.gallery-popup-img button{
    background: transparent;
    outline: none;
    border: none;
}

.gallery-popup-img i{
    font-size: 1.75rem;
    padding: 0 10px;
    color: var(--magenda);
}
.contact{
    padding-top: 60px;
}

.contact .container{
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 10px;
    gap: 10px;
}

.contact-left h1{
    font-size: 3rem;
    font-weight: 500;
    color: var(--magenda);
}

.contact-left p{
    color: var(--indigo);
    font-size: 1.2rem;
}

.contact-left p strong{
    font-weight: 600;
    color: var(--magenda);
}

input, textarea{
    border: none;
    outline: none;
    padding: 10px 5px ;
}

#number input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.con-name{
    margin: 20px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.con-name .field{
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.33);
    border-radius: 4px;
    padding-left: 15px;
}

.name{
    margin-right: 40px;
}

.con-name .field input{
    width: 100%;
    font-size: 1.1rem;
    text-transform: capitalize;
}

.con-email{
    width: 100%; 
    border: 1px solid rgba(0, 0, 0, 0.33);
    padding: 2px 0;
    margin-bottom: 20px;
    border-radius: 4px;
    padding-left: 15px;
}

.con-email input{
    width: 100%;
    font-size: 18px;
    text-transform: lowercase;
}

.con-textarea{
    border: 1px solid rgba(0, 0, 0, 0.33);
    width: 100%;
    height: 100%;
    padding-left: 15px;
    border-radius: 5px;
}

.con-textarea textarea{
    width: 100%;
    min-height: 100%;
    resize: none;
    font-size: 18px;
}

.contact button{
    padding: 15px 30px;
    border-radius: 10px;
    margin: 20px;
    background: var(--indigo);
    color: #fff;
    transition: all 0.3s ease;
    margin-left: 0;
    border: 0;
    outline: 0;
}

.contact button:hover{
    background-color: var(--magenda);
}

.contact-right{
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-container{
    width: 80%;
}
.address{
    width: 100%;
    /* text-align: center; */
}

.contact .address{
    display: flex;
    margin: 30px 0;
    align-items: center;
    /* text-align: center; */
}

.contact .address i{
    margin-right: 30px;
    font-size: 1.5rem;
    color: var(--magenda);
}

.contact .address p{
    color: var(--indigo);
    font-weight: 500;
    margin: 0;
}
.network{
    padding-top: 20px;
}
.network-item{
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 20px;
    gap: 20px;
    border-bottom: 1px solid #00000059;
    padding: 30px 0;
}

.network-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.network-left h1{
    color: var(--magenda);
    font-size: 2rem;
    margin-bottom: 10px;
}
.network-left p{
    text-align: justify;
}

.network-right{
    display: flex;
    justify-content: center;
    align-items: center;
}

.network-right img{
    width: 80%;
}
.privacy_intro_para {
  padding: 60px 0 30px;
}

.privacy * {
  text-align: justify;
}

.privacy_intro_para > h3 {
  margin: 30px 0 20px;
  font-size: 1.25rem;
  color: var(--magenda);
}

.address_details{
  color: black;
}

.privacy_intro_para ul li {
  list-style-type: circle;
}

.privacy_intro_para ul li section{
  color: black;
}

.privacy_intro_para ul {
  padding-left: 30px;
}

.privacy_intro_para ul li h3 {
  color: var(--magenda);
}
.privacy_intro_para ul li p {
  font-weight: 500;
}
.privacy_intro_para ul li p span {
  font-weight: 600;
  margin-left: 10px;
  color: var(--indigo);
}

.privacy_overview {
  padding: 30px 0 30px;
}
.privacy_overview h1 {
  font-size: 2rem;
  color: var(--magenda);
  margin-bottom: 10px;
}

.privacy_overview ul {
  padding-left: 30px;
}
.privacy_overview ul li {
  list-style-type: circle;
  margin: 10px 0;
}
.privacy_overview ul li h3 {
  display: inline;
  font-weight: 600;
  color: var(--indigo);
}

.table_of_content h2 {
  font-size: 1.75rem;
  margin-bottom: 20px;
  color: var(--magenda);
}

.table_of_content ul {
  padding-left: 30px;
}
.table_of_content ul li {
  list-style-type: square;
  padding-left: 5px;
  font-size: 1rem;
  text-transform: capitalize;
  margin: 5px 0;
}
.table_of_content ul li a {
  cursor: pointer;
}

.about_the_website,
.scope_policy,
.personal_information,
.other_information,
.choices_access,
.children,
.advertising_marketing,
.third_party_services,
.security,
.border_transfers,
.change_policy,
.report_abuse {
  padding: 80px 0 30px;
}

.privacy_para_heading {
  font-size: 2rem;
  margin-bottom: 20px;
  color: var(--magenda);
  text-transform: uppercase;
}

.about_the_website ul {
  padding-left: 30px;
}
.about_the_website ul li {
  list-style-type: circle;
  margin: 10px 0;
}
.about_the_website ul li ul li {
  list-style-type: square;
}

.scope_policy ul li {
  padding: 10px 0 10px 20px;
}

.personal_information > ul {
  padding-left: 20px;
}
.personal_information > ul > li {
  margin: 20px 0;
  list-style-type: upper-alpha;
}
.personal_information > ul > li h3 {
  color: var(--indigo);
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.personal_information > ul > li > ul > li {
  padding: 10px 0;
}
.personal_information > ul > li > ul > li > ul {
  padding: 20px 25px;
}
.personal_information > ul > li > ul > li > ul > li {
  list-style-type: square;
}

.personal_information > ul > li:nth-child(2) ul > li,
.personal_information > ul > li:nth-child(3) ul > li,
.personal_information > ul > li:nth-child(4) ul > li {
  list-style-type: disc;
}

.personal_information h5 {
  font-size: 1rem;
  margin-top: 20px;
  color: rgb(32, 32, 32);
}

.other_information > ul {
  padding-left: 20px;
}
.other_information > ul > li {
  margin: 20px 0;
  list-style-type: upper-alpha;
}
.other_information > ul > li h3 {
  color: var(--indigo);
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.other_information > ul > li > ul > li {
  padding: 10px 0;
}
.other_information > ul > li > ul > li > ul {
  padding: 20px 25px;
}
.other_information > ul > li > ul > li {
  list-style-type: circle;
}

.other_information h5 {
  font-size: 1rem;
  margin-top: 20px;
  color: rgb(32, 32, 32);
}

.choices_access > ul {
  padding-left: 20px;
}
.choices_access > ul > li {
  margin: 20px 0;
  list-style-type: upper-alpha;
}
.choices_access > ul > li h3 {
  color: var(--indigo);
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.choices_access > ul > li > ul > li {
  padding: 10px 0;
}
.choices_access > ul > li > ul > li > ul {
  padding: 20px 25px;
}
.choices_access > ul > li > ul > li {
  list-style-type: circle;
}

.choices_access h5 {
  font-size: 1rem;
  margin-top: 20px;
  color: rgb(32, 32, 32);
}

.list_point_bold {
  font-weight: 600;
}

.children h5 {
  font-size: 1rem;
  margin-top: 20px;
  color: rgb(32, 32, 32);
}

.children > ul {
  padding-left: 20px;
}
.children > ul > li {
  margin: 20px 0;
  list-style-type: upper-alpha;
}
.children > ul > li h3 {
  color: var(--indigo);
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.advertising_marketing > ul {
    padding-left: 20px;
  }
  .advertising_marketing > ul > li {
    margin: 20px 0;
    list-style-type: upper-alpha;
  }
  .advertising_marketing > ul > li h3 {
    color: var(--indigo);
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
.third_party_services > ul {
    padding-left: 20px;
  }
  .third_party_services > ul > li {
    margin: 20px 0;
    list-style-type: upper-alpha;
  }
  .third_party_services > ul > li h3 {
    color: var(--indigo);
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
.security > ul {
    padding-left: 20px;
  }
  .security > ul > li {
    margin: 20px 0;
    list-style-type: circle;
  }
.border_transfers > ul {
    padding-left: 20px;
  }
  .border_transfers > ul > li {
    margin: 20px 0;
    list-style-type: circle;
  }

  .privacy a{
    margin: 0 5px;
    font-weight: 700;
    color: var(--indigo);
  }

  .report_abuse span{
    font-weight: 600;
    display: block;
  }
@media (max-width: 1300px) {

}
@media (max-width: 1200px) {
  
.about_right .group_pic::before{
  width: 150px;
  height: 150px;
}
.about_right .group_pic::after{
  width: 150px;
  height: 150px;
}
}
@media (max-width: 1100px) {
  .about_right .group_pic{
    width: 100%;
  }
  .career .container .row{
    grid-template-columns: 1fr 1fr;
  }
  .faq h4{
    font-size: 0.75rem;
}
}

@media (max-width: 992px){
  .head .container{
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.sampurna .container{
  grid-gap: 20px;
  gap: 20px;
  padding: 2rem 1.75rem;
}

.sam_left h1{
  font-size: 2rem;
}
.sam_right p{
  font-size: 1rem;
}
.services .container{
  grid-template-columns: 1.5fr 1fr;
}
.about_left{
  width: 50%;
}
.about_right{
  width: 45%;
}
.about_left h1{
  font-size: 2.25rem;
  margin-bottom: 2rem;
}

.about_left h1::before{
  font-size: 0.90rem;
}

.about_left p{
  font-size: 0.85rem;
}
.about_right .group_pic::before{
  width: 120px;
  height: 120px;
}
.about_right .group_pic::after{
  width: 120px;
  height: 120px;
}
.vision .container{
  padding: 40px 0 20px;
}
.vision_left h1{
  font-size: 1.5rem;
}
.first_one h4, .sec_one h4{
  font-size: 1rem;
}
.first_one p{
  margin-bottom: 20px;
}
.first_one p,.sec_one p{
  font-size: 0.75rem;
}

.testimonial .container .testi_heading h1{
  font-size: 2.25rem;
}
.testimonial .swiper-slide{
  grid-template-columns: 1fr;
  height: auto;
}

.test_left{
  height: 300px;
}
 .test_left .test_img{
  position: relative;
  bottom: 0;
  right: 0;
  width: 200px;
  height: 200px;
}
.test_left .test_img::before{
  width: 150px;
  height: 150px;
  top: -50px;
  left: -50px;
}
.test_right{
  width: 100%;
}
.test_right p{
  width: 100%;
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 20px;
}
.test_right h2{
  text-align: center;
  font-size: 1.25rem;
  width: 100%;
}
.test_right h4{
  text-align: center;
  font-size: 1rem;
  width: 100%;
}
footer .container{
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 50px;
  gap: 50px;
}
.newspaper_item{
  width: 300px;
}
.col-1 p{
  font-size: 0.90rem;
}
.mf .container .mfright {
  width: 95%;
}
.mf .container .mfleft h1{
  font-size: 2rem;
}
.mf .container .mfleft p{
  font-size: 0.9rem;
}
.socials .container .eclinic .eleft{
  width: 60%;
}
.socials .container .eclinic .eright{
  width: 40%;
}
.contact .container{
  grid-template-columns: 1fr 1fr;
}
.contact .right-container{
  width: 90%;
}

.hero_box_contain h2, #hero_box_contain_skill h2{
  font-size: 1.75rem;
}
.hero_box_contain h2 span, #hero_box_contain_skill h2 span{
  font-size: 2rem;
}

@-webkit-keyframes slideAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 50%;
  }
}

@keyframes slideAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 50%;
  }
}
}

@media (min-width: 768px) and (max-width: 878px) {
  nav .container{
    width: 95%;
  }
  .menu ul li{
    padding: 10px 5px;
  }
  .career .container .row {
    grid-template-columns: 1fr 1fr;
  }
  
.skill-comp{
  grid-template-columns: 1fr;
}
}

@media (max-width: 768px){
  .menu{
    display: none;
  }
  .menu-btn{
      display: block;
  }
  .swiper-slide{
    height: 50vh;
  }
  .sampurna .container{
    flex-direction: column;
  }
  
  .sam_left{
    border-right: 0;
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 2px solid var(--indigo);
  }
  .sam_right p{
    text-align: center;
  }
  .services .container{
    grid-template-columns: 1fr;
  }
  .about_left{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 60px;
}

.about_left h1{
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.about_left h1::before{
    font-size: 1rem;
}

.about_left p{
    font-size: 1rem;
    text-align: justify;
    color: var(--indigo);
}

.about_right{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}
.about_right .group_pic::before{
    width: 150px;
    height: 150px;
}
.about_right .group_pic::after{
    width: 150px;
    height: 150px;
}
.vision .container{
  grid-template-columns: 1fr;
}

.vision .container > div:nth-child(1){
  order: 2;
}

.vision_right .vision_pic{
  height: 300px;
  width: 100%;
}
.vision_pic img{
  object-fit: cover;
  border-radius: 0;
}

.vision_left h1{
  font-size: 2.25rem;
}

.first_one h4, .sec_one h4{
  font-size: 1.5rem;
}

.first_one p,.sec_one p{
  font-size: 1rem;
}
.testimonial .container{
  width: 90%;
}
.career .container .row {
  grid-template-columns: 1fr;
}
.col-1{
  order: 2;
  margin-top: 50px;
}
.col-1 h1{
  font-size: 2.5rem;
}
.col-1 p{
  font-size: 1.25rem;
}

.col-1 h4{
  color: var(--indigo);
  font-size: 1.25rem;
}
.col-1 > div ul li{
  font-size: 1rem;
}
.faqs_container{
  grid-template-columns: 1fr;
}
.socials .container .eclinic {
  flex-wrap: wrap;
}

.socials .container .eclinic .eleft{
  width: 100%;
}
.socials .container .eclinic .eright{
  width: 100%;
}
.skill-comp{
  grid-template-columns: 1fr;
}
.board {
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.boardright {
  order: 1;
}
.boardleft {
  order: 2;
}
.boardleft h1, .boardleft h2{
  text-align: center;
}
.boardright .img {
  width: 180px;
  height: 180px;
}
.gallery .box{
  flex-direction: column;
}
.gallery .box > div{
  width: 100%;
}
.gallery .box > div .gallery-img:last-child{
  height: auto;
}
.gallery .box div:last-child .gallery-img:nth-child(9){
  height: auto;
}
.gallery .box div:first-child .gallery-img:nth-child(10) img{
  -webkit-transform: scale(1);
          transform: scale(1);
}
.gallery .box div:first-child .gallery-img:nth-child(10) img:hover{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.gallery .box div:last-child .gallery-img:nth-child(10) img{
  -webkit-transform: scale(1);
          transform: scale(1);
}

.gallery .box div:last-child .gallery-img:nth-child(10) img:hover{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.contact .container{
  grid-template-columns: 1fr;
}
.contact-right{
  justify-content: left;
}
.name{
  margin-right: 10px;
}
.right-container{
  width: 90%;
}
/* .map iframe{
  height: 350px;
} */
.network-item{
  grid-template-columns: 3fr 1fr;
}
.network-left h1{
  font-size: 1.5rem;
}
.hero_box_contain h2, #hero_box_contain_skill h2{
  padding-left: 40px;
  font-size: 1.5rem;
}
.hero_box_contain h2 span, #hero_box_contain_skill h2 span{
  font-size: 1.70rem;
}
}

@media (max-width: 576px) {
    .head{
        display: none;
    }

    #back_img1{
      background-position: 90% center;
  }
  #back_img2{
      background-position: 30% center;
  }
  #back_img3{
      background-position: 50% center;
  }
  .about_right .group_pic::before{
    width: 120px;
    height: 120px;
    left: -20px;
  top: -20px;
}
.about_right .group_pic::after{
    width: 120px;
    height: 120px;
    right: -20px;
  bottom: -20px;
}
.vision_left h1{
  font-size: 1.75rem;
}

.first_one h4, .sec_one h4{
  font-size: 1.25rem;
}

.first_one p,.sec_one p{
  font-size: 1rem;
}
.partner .container h1{
  font-size: 1.5rem;
}
footer .container{
  grid-template-columns: 1fr;
}
.hero .container{
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}
.container .popup-img img{
  height: auto;
  width: 90%;
}
.col-1 h1{
  font-size: 2.25rem;
}
.col-1 p{
  font-size: 1rem;
}
.geo h1{
  font-size: 2rem;
}
.geo p{
  font-size: 0.9rem;
}
.map ol li{
  font-weight: 500;
  font-size: 1rem;
}
.geographical_presence .container .map img{
  width: 200px;
}
.popup-main .popup-content{
  height: auto;
  width: 90%;
}
.popup-main .popup-content img{
  height: auto;
  width: 100%;
}
.network-item{
  grid-template-columns:  1fr;
}
.hero_box_contain h2, #hero_box_contain_skill h2{
  padding-left: 10px;
  font-size: 1.25rem;
}
.hero_box_contain h2 span, #hero_box_contain_skill h2 span{
  line-height: 1.2;
  font-size: 1.75rem;
}
#hero_box_contain_skill h2{
  padding: 0;
}

@-webkit-keyframes slideAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 55%;
  }
}

@keyframes slideAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 55%;
  }
}

}

@media (max-width: 350px) {
  .sam_right p{
    font-size: 0.85rem;
  }
  .service_content p{
    font-size: 0.70rem;
  }

  .service_content span{
    color: var(--white);
    font-size: 13px;
    padding: 5px 15px;
    border: 1px solid var(--white);
    border-radius: 25px;
    font-weight: 500;
    transition: all 0.5s ease;
}
.about_left{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 60px;
}

.about_left h1{
  font-size: 2rem;
  margin-bottom: 1rem;
}

.about_left h1::before{
  font-size: 0.75rem;
}

.about_left p{
  font-size: 0.75rem;
}

.about_right{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.about_right .group_pic::before{
  width: 100px;
  height: 100px;
  left: -20px;
  top: -20px;
}
.about_right .group_pic::after{
  width: 100px;
  height: 100px;
  right: -20px;
  bottom: -20px;
}
.vision_left h1{
  font-size: 1.5rem;
}

.first_one h4, .sec_one h4{
  font-size: 1rem;
}

.first_one p,.sec_one p{
  font-size: 0.75rem;
}
.test_left .test_img{
  position: relative;
  bottom: 0;
  right: 0;
  width: 150px;
  height: 150px;
}
.test_left .test_img::before{
  width: 100px;
  height: 100px;
  top: -25px;
  left: -25px;
}

}



