.vision{
    margin-bottom: 60px;
    background-color: rgb(236, 236, 236);
}

.vision .container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    padding: 50px 0;
}

.vision_left{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.vision_left h1{
    font-size: 2.2rem;
    color: var(--magenda);
    margin-bottom: 20px;
}

.first_one h2, .sec_one h2{
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--magenda);
    margin-bottom: 10px;
}
.first_one p,.sec_one p{
    font-size: 0.9rem;
    margin-bottom: 50px;
    color: rgb(138, 138, 138);
    text-align: justify;;
}

.sec_one p{
    margin-bottom: 20px;
}

.vision_right{
    display: flex;
    align-items: center;
    justify-content: center;
}
    
.vision_right .vision_pic{
    display: flex;
    justify-content: center;
    position: relative;
}

.vision_pic img{
   width: 100%;
   border-radius: 60% 0 0 60%;
}
