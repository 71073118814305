.backgound{
    padding: 3rem 0 1rem;
}

.backgound p{
    text-align: justify;
}

.backgound p span{
    color: var(--magenda);
    font-weight: 600;
    font-size: 1.1rem;
}