.about_us{
    margin: 120px 0 80px;
}

.about_us .container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.about_left{
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about_left h1{
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--magenda);
    position: relative;
    margin-bottom: 2rem;
}

.about_left p{
    font-size: 1rem;
    text-align: justify;
    color: var(--indigo);
}

.about_right{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.about_right .group_pic{
    width: 80%;
    height: 100%;
    border: 2px solid var(--white);
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.476);
}

.about_right .group_pic img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}
.about_right .group_pic::before{
    position: absolute;
    width: 180px;
    height: 180px;
    background: var(--magenda);
    content: "";
    top: -30px;
    left: -30px;
    z-index: -1;
}
.about_right .group_pic::after{
    position: absolute;
    width: 180px;
    height: 180px;
    background: var(--magenda);
    content: "";
    bottom: -30px;
    right: -30px;
    z-index: -1;
}