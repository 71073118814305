.gallery{
    padding-top: 60px;
}

.gallery .box{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.gallery .box > div{
    display: flex;
    flex-direction: column;
    width: 32.5%;
}
.gallery .box .gallery-img{
    overflow: hidden;
    border-radius: 20px;
    margin-bottom: 10px;
}
.gallery .box .gallery-img img{
    width: 100%;
    transition: all 0.5s linear;
    object-fit: cover;
    object-position: center;
}

.gallery .box > div .gallery-img:last-child{
    height: 250px;
}
.gallery .box div:last-child .gallery-img:nth-child(9){
    height: 370px;
}
.gallery .box div:first-child .gallery-img:nth-child(10) img{
    transform: scale(1.1);
}
.gallery .box div:first-child .gallery-img:nth-child(10) img:hover{
    transform: scale(1.2);
}
.gallery .box div:last-child .gallery-img:nth-child(10) img{
    transform: scale(1.2);
}

.gallery .box div:last-child .gallery-img:nth-child(10) img:hover{
    transform: scale(1.3);
}

.gallery-popup-img{
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.925);
    height: 100%;
    width: 100%;
    z-index: 99999999;
    display: block;
}

.gallery-popup-img span{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 40px;
    font-weight: bolder;
    color: var(--magenda);
    cursor: pointer;
    z-index: 999999999;
}

.popup-main{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.popup-main .popup-content{
    height: 90%;
    width: auto;
    border-radius: 5px;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gallery .box .gallery-img:hover img{
    transform: scale(1.1);
}

.popup-main .popup-content img{
    height: 80%;
    border-radius: 5px;
}
.popup-main .popup-content p{
    font-size: 0.9rem;
    text-align: center;
    width: 90%;
    color: var(--white);
}

.gallery-popup-img button{
    background: transparent;
    outline: none;
    border: none;
}

.gallery-popup-img i{
    font-size: 1.75rem;
    padding: 0 10px;
    color: var(--magenda);
}