.geographical_presence{
    padding-top: 60px;
}

.geo h1{
    color: var(--magenda);
    font-size: 2.25rem;
    margin-bottom: 20px;
}
.geo p{
    /* border: 1px solid red; */
    margin-bottom: 3rem;
}

ol li{
    font-weight: 500;
    color: var(--magenda);
    padding: 3px 0;
    text-transform: uppercase;
}

ol li ul li{
    font-size: 0.75rem;
    padding-left: 20px;
    color: var(--indigo);
    text-transform: capitalize;
}

.geographical_presence .container .map {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 450px;
    /* border: 1px solid red; */
    margin-top: 20px;
}

.geographical_presence .container .map img{
    width: 350px;
}
