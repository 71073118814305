.swiper-slide{
    width: 100%;
    height: 95vh;
}

.back_img{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

/* .back_img h1{
    font-size: 3rem;
    width: 90%;
    margin: auto;
    color: #fff;
} */

#back_img1{
    background: url(../../resource/Banner/Home_BG_1.jpg);
    /* box-shadow: inset 0 0 0 2000px rgba(0, 0,0, 0.3); */
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
#back_img2{
    background: url(../../resource/Banner/Home_BG_2.jpeg);
    /* box-shadow: inset 0 0 0 2000px rgba(0, 0,0, 0.3); */
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
#back_img3{
    background: url(../../resource/Banner/Home_BG_3.jpg);
    background-size: cover;
    /* box-shadow: inset 0 0 0 2000px rgba(0, 0,0, 0.3); */
    background-position: center center;
    background-repeat: no-repeat;
}

.sampurna{
    width: 100%;
    position: relative;
    top: -50px;
    z-index: 2;
}

.sampurna .container{
    padding: 2.5rem 2rem;
    background-color: #fff;
    display: flex;
    gap: 50px;
    box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.422);
}

.sam_left{
    width: 60%;
    border-right: 2px solid var(--indigo);
    display: flex;
    align-items: center;
    justify-content: center;
}

.sam_left h1{
    font-size: 2.5rem;
    color: var(--magenda);
    letter-spacing: 2px;
}

.sam_right{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sam_right p{
    font-size: 1.2rem;
    color: var(--indigo);
    text-align: justify;
}

@keyframes slideAnimation {
    0% {
      width: 0;
    }
    100% {
      width: 45%;
    }
  }
  
  .hero_box_contain {
    margin-top: 150px;
    width: 0;
    height: 120px;
    position: relative;
    background: var(--indigo);
    animation: slideAnimation 0.5s ease-in-out forwards;
    display: flex;
    align-items: center;
}

#hero_box_contain_skill{
    margin-left: auto;
}

#hero_box_contain_skill::before{
    position: absolute;
    content: "";
    width: 86px;
    height: 86px;
    /* border: 1px solid red; */
    top: 17px;
    left: -42px;
    transform: rotate(45deg);
    background: var(--indigo);
    /* opacity: 0.8; */
}

.hero_box_contain:after{
    position: absolute;
    content: "";
    width: 86px;
    height: 86px;
    /* border: 1px solid red; */
    top: 17px;
    right: -42px;
    transform: rotate(45deg);
    background: var(--indigo);
    /* opacity: 0.8; */
}

#hero_box_contain_skill h2{
    color: var(--white);
    opacity: 0;
    animation: textAnimation 0.5s ease-in-out forwards 0.5s;
    padding-left: 40px;
    text-transform: capitalize;
    /* font-family: 'Cantora One', sans-serif; */
    font-weight: 400;
    font-size: 2rem;
}
#hero_box_contain_skill h2 span{
    color: var(--white);
    /* font-family: 'Kaushan Script', cursive; */
    font-size: 2.5rem;
    line-height: 1;
    font-weight: 400;
}
.hero_box_contain h2 {
    color: var(--white);
    opacity: 0;
    animation: textAnimation 0.5s ease-in-out forwards 0.5s;
    padding-left: 50px;
    text-transform: capitalize;
    /* font-family: 'Cantora One', sans-serif; */
    font-weight: 600;
    font-size: 2rem;
    z-index: 2;
}

.hero_box_contain h2 span{
    color: var(--white);
    /* font-family: 'Cantora One', sans-serif;
    font-family: 'Kaushan Script', cursive; */
    font-size: 2.5rem;
    line-height: 1;
    font-weight: 400;
}


@keyframes textAnimation {
    0% {
        /* width: 60%; */
        opacity: 0;
    }
    100% {
        /* width: 100%; */
      opacity: 1;
    }
  }