.partner{
    margin-bottom: 60px;
}

.partner .container h1{
    text-align: center;
    padding: 2rem;
    font-size: 2.3rem;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--magenda);
}

.partner .container .swiper-slide{
    width: 100px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lender_item img{
    width: 100%;
    aspect-ratio: 3/2;
    object-fit: contain;
}